
import { defineComponent } from "vue";
import { requiredField } from "@/util/form-rules";
import FormLoading from "../../layout/FormLoading.vue";
import useForm from "@/modules/useForm";
import Aula from "@/interfaces/Aula";

interface Form {
  titulo: string;
  texto: string;
  aula_id: string;
}

const baseForm: Form = {
  titulo: "",
  texto: "",
  aula_id: "",
};

export default defineComponent({
  components: { FormLoading },
  setup(props, { emit }) {
    const { show, form, loading, submit, afterSubmit } = useForm(
      "duvidas",
      "duvidas",
      "a",
      baseForm,
      emit,
      "duvidas"
    );

    const open = (aula: Aula): void => {
      console.log(aula);
      form.value = JSON.parse(JSON.stringify(baseForm));
      form.value.aula_id = aula.id;
      show.value = true;
    };

    return {
      show,
      open,
      form,
      requiredField,
      loading,
      submit,
      afterSubmit,
    };
  },
});
